import Cookies from 'js-cookie';

const news = [
  {
    date: 'November 8th, 2024',
    title: 'V2 Estimate - Improved Detailed Budget Layout Preferences',
    description: `<p>We've added the ability to show or hide notes, files and tag icons for cost codes and detail rows in the layout preferences for V2 estimates. More advanced detailed budget layout preferences will be coming soon.</p>`
  },
  {
    date: 'November 7th, 2024',
    title: 'New Directory Page',
    description: `<p>We've added a new Directory page in the Settings area! This page will help you manage contacts more effectively and lays the groundwork for our upcoming Bid Management feature.</p>`
  },
  {
    date: 'November 6th, 2024',
    title: 'V2 Estimate - View Cost Codes By Tag',
    description: `<p>We've introduced a new feature in V2 estimates that lets you view all cost codes linked to a tag directly on the estimate analytics page. This update simplifies finding and managing cost codes associated with specific tags.</p>`
  },
  {
    date: 'November 5th, 2024',
    title: 'V2 Estimate - Tagging Improvements',
    description:
      "<p>We've added the ability to quick edit a tag in v2 estimates by clicking on a tag and selecting a new tag. This should speed up the process of updating tags for cost codes.</p>"
  },
  {
    date: 'November 5th, 2024',
    title: 'V2 Estimate - Tag Detail Rows',
    description: `<p>Tagging is now available for detail rows in V2 estimates. This allows for more flexibility in how cost data is tracked and reported. If you'd like a demo or more information, please reach out to support.</p>`
  },
  {
    date: 'November 5th, 2024',
    title: 'Record Templates',
    description: `
  <p>We've fully redesigned the Cost and Design Data section in record templates to make managing and updating cost and design information easier than ever. This update introduces powerful new features, including:</p>
  
  <ul class='list-decimal list-inside pl-4 mb-2'>
    <li>Enhanced control over detail rows:
      <ul class='list-disc list-inside pl-4'>
        <li>Add, tag, bulk edit, and delete rows</li>
        <li>Set market links, units of measure (UOMs), and tags in bulk</li>
      </ul>
    </li>
    
    <li>Streamlined field management:
      <ul class='list-disc list-inside pl-4'>
        <li>Bulk edit, move between categories, or delete fields</li>
        <li>Bulk assign market links, UOMs, and tags</li>
      </ul>
    </li>
    
    <li>Improved user experience:
      <ul class='list-disc list-inside pl-4'>
        <li>Quickly add multiple categories, fields, and detail rows at once</li>
      </ul>
    </li>
  </ul>
  
  <p>For more details on these updates, please reach out to our support team.</p>

`
  },
  {
    date: 'October 31st, 2024',
    title: 'V2 Estimate - Tagging',
    description: `<p>Tagging is now available in V2 estimates. You can tag cost codes to enable more detailed analytics, helping you gain insights by tracking key metrics. Tagging for sub-cost fields is coming soon. If you'd like assistance or a demo, please reach out to support.</p>`
  },
  {
    date: 'October 29th, 2024',
    title: 'V2 Estimate - Performance Improvements',
    description: `<p>We've been working hard to improve the overall performance of v2 estimates. Selecting a template while creating a new estimate is now 60% faster than before.</p>`
  },
  {
    date: 'October 23rd, 2024',
    title: 'V2 Estimate Unit Cost Formulas - Cost Categories',
    description: `<p>Unit cost formulas in v2 estimates now support referencing cost categories. This allows for more flexibility in how unit costs are calculated.</p>`
  },
  {
    date: 'October 22nd, 2024',
    title: 'SSO OpenID Connect',
    description: `<p>Zebel now supports SSO OpenID Connect authentication. Please reach out to support for more information.</p>`
  },
  {
    date: 'October 18th, 2024',
    title: 'File Upload Improvements',
    description: `<div>
      <p>File uploads across Zebel have been completelty rebuilt. The new file uploader is faster, more reliable, and supports a wider variety of file types. Some of the new features include:</p>
      <ul class='list-disc list-inside'>
        <li>PDFs and images can now be previewed in the browser before downloading</li>
        <li>The uploader supports drag and drop, and can upload multiple files at once</li>
        <li>The uploader will automatically retry failed uploads, and will alert you if any uploads fail</li>
        <li>Files can now be attached to cost codes in v2 estimates</li>
      </ul>
      </div>`
  },
  {
    date: 'October 18th, 2024',
    title: 'Custom Value Units',
    description: `
      <p>Value units for design fields can now be customized in settings. This will allow for more flexibility for how many decimal places are displayed. Please reach out to support if you need help configuring these settings.</p>
      `
  },
  {
    date: 'October 11th, 2024',
    title: 'V2 Estimate Consolidation',
    description: `
      <p>
      V2 estimates now support consolidation. This allows you to combine multiple estimates into a single consolidated estimate. Please reach out to support if you need help with this feature.
      </p>`
  },
  {
    date: 'October 8th, 2024',
    title: 'V2 Estimate Indirect Cost Templates',
    description: `
      <p>
      Indirect costs for V2 estimates can now be setup inside of the record template settings. This will allow indirect costs to be more dynamic and flexible, as they now support custom formulas.
      </p>`
  },
  {
    date: 'October 1st, 2024',
    title: 'V2 Estimate Unit Cost Formulas',
    description: `
      <p>
     Custom formulas can now be used for unit cost overrides in V2 estimates. This will allow for more flexibility in how unit costs are calculated.
      </p>`
  }
];

const cookieName = 'zebel-news-last-seen-title';

const hasUnseenNews = () => {
  const lastSeenTitle = Cookies.get(cookieName);
  return !lastSeenTitle || news[0].title !== lastSeenTitle;
};

const updateLastSeenNews = () => {
  if (news.length > 0) {
    Cookies.set(cookieName, news[0].title, { expires: 365 });
  }
};

export { news, hasUnseenNews, updateLastSeenNews };
